.claims-page {
	background-image: url(../../images/claims.png);
	background-repeat: no-repeat;
	background-size: cover;
}

.claims-page .toggle-side-text {
    color: white;
    text-align: center;
    font-family: Chakra Petch;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 0.25em;
    margin-left: 0.5em;
    font-size: 18px;
}