.title-container {
  border-bottom: 2px solid #dad5d2;
  border-top: 2px solid #dad5d2;
  padding:15px;
  background: rgba(255,255,255,0.1) !important;
}

.title-container .title-text {
  color: white;
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.08em;
}

.stats-container {
  padding: 1em;
  font-family: Chakra Petch;
  font-style: normal;
}

.stats-container.blue .title-text {
  color: #256fff;
}

.stats-container.orange .title-text {
  color: #ff6b00;
}

.stats-container .title-text {
  font-weight: normal;
  line-height: 23px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.stats-container .title-value {
  font-weight: bold;
  line-height: 31px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: right;
}

.rank-container {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ffffff;
  background: rgba(255,255,255,0.05) !important;
}

.cadet-progress {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  text-align: right;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.title {
  font-family: Chakra Petch;
  font-style: normal;
}

.title .rank {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;

  color: #ff6b00;
}

.title .cadet {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.08em;
}
