.heading-dotted-container {
  background: rgba(160, 160, 160, 0.5);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;
}

.heading-text {
  color: white;
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  padding-left: 1em;
}

.heading-dotted-container .dotted-border {
  /*background: url(../../images/bottom-dotted.png);*/
  height: 2px !important;
  margin-top: 2px;
  border-bottom: 0px !important;
}