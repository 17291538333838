.ido {
font-family: Chakra Petch;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
text-transform: capitalize;

color: #FFFFFF;
}

.pool-row {
	font-family: Chakra Petch;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 18px;
text-align: right;
letter-spacing: 0.06em;
text-transform: uppercase;
color: #FFFFFF;
}

.project-status {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
	color:  white;
}

.ido img {
	max-height: 25em;
	border-radius: 50%;
}

.back-btn-div {
	position: relative;
	top:  0;
	left:  0;
}

.back-btn-div i{
	margin-right: 10px;
}

.form-sample-text {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 18px;
	text-transform: uppercase;
	color:  white;
}