.mission {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mission-divider {
  height: 2px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
}

.mission .title {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;

  color: #256fff;
}

.mission h4 {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.mission .description {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;

  color: rgba(255, 255, 255, 0.4);
}

.btn-wrapper .btn {
  height: 13px;
  font-size: 10px;
  border: 1px solid;
  border-radius: 0;
  text-transform: uppercase;
}

.btn-wrapper .btn.secondry {
  line-height: 0;
  color: #ffffff;
  opacity: 0.7;
}

.btn-wrapper .btn.primary {
  line-height: 0;
  color: #0b0b0b;
  border: 1px solid #ff6b00;
  background-color: #ff6b00;
}

i.fas.active {
  color: #ff6b00;
}
