.ido-card {
	padding: 0 1.5rem !important; 
}

.card-container .card-icon {
	height: 100%;
	max-height: 100px;
}

.card-container p {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 1.25rem;
	letter-spacing: 0.06em;
	/* text-transform: capitalize; */

	color: #FFFFFF
}


.card-container .card-title {
	border-bottom: 1px solid white;
	line-height: 2rem;
}

img.social-icons {
	margin: 1rem 1rem 0 0;
	width: 1.75rem;
}

.card-container .stats span {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 1.5rem;
	letter-spacing: 0.06em;
	text-transform: capitalize;
	color: #FFFFFF;
}

.card-container .status {
	font-size: 1rem;
	color: white;
	font-weight: bold;
	text-transform: uppercase;
}

.card-container .status > div {
	justify-content: space-between;
}

.card-container .progress.single {
	background: grey !important;
	box-shadow: none;
}

.card-container .progress-bar.single {
  background: #FF6B00;
  box-shadow: 0px 0px 8px #FF6B00;
}