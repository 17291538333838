.heading-container {
  background: rgba(255, 255, 255, 0.12);
  padding: 5px 0px;
}

.heading-text {
  color: white;
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
}
