.claims-page .project-stats{
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.claims-page .ctx-container {
    background: rgba(255, 255, 255, 0.05);
}

.claims-page .project-title{
    font-size: 24px;
    line-height: 48px;
}

.claims-page img {
    max-height: 12em;
    padding: 1em;
}

.claims-page .tranche-details {
    padding: 1em;
}

.claims-page .tranche-container {
    background: rgba(255, 255, 255, 0.1);
    padding: 1em;
}


.claims-page .tranche-heading {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

.claims-page .tranche-text {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    text-align: center;
    justify-content: space-between;
    padding: 1em 0;
}

.claims-page .tranche-claim-status {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
}