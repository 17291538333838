.vault-page {
	background-image: url(../../images/vault.png);
	background-repeat: no-repeat;
	background-size: cover;
}

.totals {
	background: rgba(9, 9, 9, 0.5);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    padding: 0.5em;
      color: white;
	  text-align: center;
	  font-family: Chakra Petch;
	  text-transform: uppercase;
	  font-weight: bold;
}

.totals-title {
  font-size: 18px;
}

.totals-stats {
	font-size: 32px;
}