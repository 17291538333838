/* Root  */
body {
 background: linear-gradient(242.06deg, #321500 0%, #00061A 53.97%);
}

a {
 text-decoration: none;   
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(30,30,30,0.9);
}

::-webkit-scrollbar-thumb {
  background: rgb(0, 65, 255, 0.8);
}

/* Header */
.navbar {
  height: 6rem;
  padding-left: 37px;
  border-bottom: 2px solid #dad5d2;
}

.navbar-brand {
  position: relative;
}

.navbar-custom {
  padding-left: 37px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.navbar-sl {
  background: #00061A;
}

.header-title {
  position: absolute;
  left: 36px;
  top: 0;
  font-family: Chakra Petch;
}

.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -1px;
  color: #ff6b00;
  margin-left: 3px;
}

.sub-heading {
  position: absolute;
  top: 25px;
  left: 3px;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}

.notification {
  padding: 0.75rem;
}

header .wallet-btn {
  padding: 8px 20px;
  /* margin-left: 30px; */
  margin-top: 10px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  background: #ff6b00;
  font-weight: 600;
}

header .wallet-adapter-button {
  background-color: #ff6b00 !important;
  padding: 10px;
  font-size: 14px;
  height: unset;
  line-height: unset;
  color: #fff;
  border: none;
}

.wallet-adapter-modal-wrapper {
background: rgba(20, 20, 20, 0.9) !important;
}

.wallet-adapter-button {
  background: rgba(255,255,255,0.1) !important;
}

header .wallet-adapter-button-start-icon {
  margin-left: 0px;
  width: 18px;
  height: 18px;
}

header .wallet-adapter-dropdown-list {
  display: grid;
  grid-row-gap: 1px;
  background: rgba(50,50,50,0.7);
}

header .wallet-adapter-dropdown-list-item {
  background: rgba(255,255,255,0.15);
}

/* Main */
main {
  margin-top: 6rem;
  height: 100%;
}

.left-col {
  background-image: url(./images/dots.png);
  background-size: cover;
}

.right-col {
  background-size: cover;
}

.daily-missions-container {
  height: 50%;
}

.create-text {
  color: white;
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    padding: 10px;
}

.subtext .wallet-adapter-button {
  margin-top: 10px;
  background-color: #ff6b00 !important;
  border:  0px !important;
  padding: 10px 20px;
  color: white;
}

.vault-content .wallet-adapter-button {
  margin-top: 10px;
  background-color: #ff6b00 !important;
  border:  0px !important;
  padding: 10px 20px;
  color: white;
}


.create-btn {
  margin-top: 10px;
  background-color: #ff6b00;
  border:  0px !important;
  padding: 10px 20px;
  color: white;
  margin-left: 20px;
  border-radius: 20px;
}


.daily-missions-container::-webkit-scrollbar {
  width: 0.4em;
  background: rgba(255, 255, 255, 0.08);
}
 
.daily-missions-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.daily-missions-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 1);
  outline: 1px solid slategrey;
}

.bracket-containers span {
}

.top-officers-header {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  padding: 15px 20px;
}

.top-officers-header span {
  text-transform: uppercase;
  color: #ff6b00;
  font-size: 18px;
}

.mission:last-child {
  border: none;
}


/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 6rem 0 0; /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  z-index: 600;
}

@media (max-width: 480px) {

  .sidebar-title{
    color: white;
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 23px;
    text-transform: uppercase;
    padding: 1em;
    text-align: left;
  }

  .sidebar a {
    text-decoration: none;
  }

  .sidebar span{
    color: white;
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    text-transform: uppercase;
    padding-left: 1em;
  }

  .list-group-mine .list-group-item {
    text-align: left;
    display: flex;
    flex-direction: row;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-menu {
  width: 6rem;
  background: #00061A;
}

i.fas,
i.far {
  color: grey;
}

.list-group {
  background: none;
  margin-left: 0px !important;
}

.list-group-mine .list-group-item {
  background-color: #00061A;
  border-left: 1px solid transparent;
  text-align: center;
  padding: 20px;
}

li.list-group-item:hover {
  background: rgba(0, 56, 139, 0.18);
  border-left: 1px solid #256FFF;
}

li.list-group-item.active {
  background: rgba(0, 56, 139, 0.18);
  border:  0px;
  border-radius: 0px;
  border-left: 1px solid #256FFF;
}

.blurred {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

/* Buttons */

.btn-orange {
  background: #ff6b00 !important;
}

.btn-blue {
  background: #256FFF !important;
}

.btn-grey {
  background: #212224 !important;
}

/* Text */
.text-blue {
  color: #256FFF;
}

.text-orange {
  color: #FF6B00;
}

.text-green {
  color: rgba(45, 158, 6, 1);
}

/* Pages */
.page-title {
  color: white;
  text-align: center;
  font-family: Chakra Petch;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 36px;
}

.page {
  overflow-y: scroll;
  padding-bottom: 8rem !important;
  margin:  0;
}


/* fonts */

.font-m {
  font-size: 14px;
}

.font-n {
  font-size: 16px;
}

.font-x {
  font-size: 18px;
}

.font-xl {
  font-size: 20px;
}


.font-xxl {
  font-size: 24px;
}

/* Width  */
.w-48 {
  width: 48% !important;
}

.w-90 {
  width: 90% !important;
}

/* Navbar  */
.navbar-toggler-icon {
  color: white;
  font-size: 2rem;
  position: absolute;
  top:  0;
  left: 0;
  margin: 30px 10px;
}

/* Media queries */

@media (min-width: 576px) {
  main {
    padding-left: 6rem;
  }
}

@media (max-width: 576px) {
  .navbar {
    padding: 0;
  }

  .sidebar .fa-bars {
    margin: 1.5rem 1rem;
    font-size: 24px;
    color: white;
  }

  .top-row {
    overflow-x: hidden;
  }

  .page {
    overflow-x: hidden !important;
  }

  .notification {
    padding: 0.75rem 0;
  }

  .stats-container {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .stats-container .title-text {
    font-size: 16px;
    text-align: center !important;
    width: 100%;
  }

  .stats-container .title-value {
    font-size: 24px;
    width: 100%;
    text-align: center !important;
  }

  .navbar {
    padding: 0 !important;
  }

  .sidebar {
    padding: 0;
    position: absolute;
    z-index: 1032;
  }

  .sidebar-modal {
    width: 100% !important;
    z-index: 1031;
    padding: 0;
    color: white;
    text-align: center;
    font-family: Chakra Petch;
    text-transform: uppercase;
  }
    
  .sidebar-modal .modal-dialog {
    position: absolute;
    margin:  0;
    border-radius: 0;
    height: 100%;
    background: transparent;
    width: 100%;
  }

  .sidebar-modal .modal-header {
      font-size: 18px;
      font-weight: bold;
  }

  .sidebar-modal .modal-content {
    position: absolute;
    margin:  0;
    border-radius: 0;
    height: 100%;
    background: #00061A;
    width: 65%;
    color: white;
  }

  .sidebar-modal .modal-body{
    padding: 0;
  }

  .sidebar-modal a, span {
    color: white;
    text-decoration: none;
    font-size: 14px;
  }

  .sidebar-modal .item-text{
    margin-left: 10px;
  }

  .navbar {
    height: 5rem;
  }

  .navbar-brand {
    margin-left: 40px;
  }
}

@media (max-width: 480px) {
  .sidebar-modal .modal-content {
    width: 50% !important;
  }
}

/*  Slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ff6b00;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff6b00;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}