.progress {
  background-color: #353744 !important;
  height: 6px !important;
}

.progress.single {
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
  margin-bottom: 1.5em;
}

.progress-bar {
  background-color: white !important;
}

.progress-bar.single {
  background-color: #ff6b00 !important;
}
