.top-officers-stats .headings {
  color: white;
  padding: 10px;
}

.top-officers-stats .progress {
  border-radius: 0px;
}

.top-officers-stats {
  font-size: 18px;
  line-height: 23px;
}
