.vault-btn {
	border: 1px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: 1px;
	background: transparent;
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	color: #FFFFFF;
	padding: 5px 15px;
	text-decoration: none;
}

.vault-btn:hover {
	color: white !important;
	cursor: pointer;
}

.vault-content {
	display: flex;
	flex: 1;
	border-radius: 5px;
	justify-content: center;
    align-items: center;
    border-radius: 0;
    flex-direction: column;
}

.vault-plus {
	margin-bottom: 1rem;
	cursor: pointer;
	width: 60%;
}

.vault-container {
	background: rgba(9, 9, 9, 0.5);
	backdrop-filter: blur(20px);
	min-height: 240px;
	clear: both;
	width: 100%;
	max-width: 450px;
	padding: 1em;
	margin: 1em 0;
}

.vault-tr-border-tr {
	position: absolute;
	top: 0;
	right: 0;
	width: 25px;
	height: 25px;
	margin: 20px;
	border-top: 1px solid white;
	border-right: 1px solid white;
}

.vault-tr-border-tl {
	position: absolute;
	top: 20%;
	left: 5%;
	width: 15%;
	height: 10%;
	background-image: url(../../images/top-left-border.png);
	background-repeat: no-repeat;
	background-size: fill;
	text-align: right;
}

.vault-tr-border-bl {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 25px;
	height: 25px;
	margin: 20px;
	border-left: 1px solid white;
	border-bottom: 1px solid white;
}

.vault-tr-border-br {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 30%;
	height: 5px;
	margin: 20px;
	border-bottom: 2px solid white;
}

.vault-name {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: normal;
	font-size: 21px;
	line-height: 23px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #FFFFFF;
	text-align: left;
	padding-bottom: 10px;
	font-weight: bold;
}

.vault-stats span {
	margin-left: 1rem;
}

.vault-details {
	justify-content: space-between;
}

.vault-details button {
}

.vault-stats {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: white;
	width: 90%;
}

.vault-divider {
	height: 1px !important;
	background: #FFFFFF;
	box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.4);
	transform: matrix(1, 0, 0, -1, 0, 0);
	padding: 0 !important;
}

.vault-stats > div{
	padding: 15px 0;
	justify-content: space-between;
}

.vault-modal {
	width: 50%;
	max-width: 750px !important;
}

.vault-create-modal {
	border-radius: 0;
	background: #090909;
}

.vault-create-modal .locked{
	margin:  10px 20px 0 0;
}

/* Forms */
.vault-form {
	text-transform: uppercase;
	padding: 4rem;
	padding-top: 0rem;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
}

.vault-form label {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
}

.vault-form input {
	border-radius: 0;
	height:36px;
	background: linear-gradient(180deg, rgba(51, 64, 89, 0.6) 35.42%, rgba(39, 48, 65, 0.12) 100%);
	border:  0;
	margin-bottom: 10px;
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: bold;
	font-size: 30.7317px;
	line-height: 40px;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.06em;
	text-transform: uppercase;

	color: #FFFFFF;
	padding: 25px;
}

.vault-form .input-group span {
	border-radius: 0;
	height:36px;
	background: linear-gradient(180deg, rgba(51, 64, 89, 0.6) 35.42%, rgba(39, 48, 65, 0.12) 100%);
	border:  0;
	margin-bottom: 10px;
	font-family: Chakra Petch;
	font-style: normal;
	font-size: 24px;
	line-height: 40px;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.06em;
	text-transform: uppercase;

	padding: 25px;
}

.vault-form .btn {
	justify-content: space-around;
}

.vault-form .ig-blue span{
	color: #256FFF;
}

.vault-form .ig-orange span{
	color: #ff6b00;
}

.vault-form-title {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: normal;
	font-size: 21px;
	line-height: 23px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #FFFFFF;
	text-align: left;
	padding-bottom: 10px;
	font-weight: bold;
	justify-content: space-around;
}

.vault-form .form-check input{
	height: 16px;
	border-radius: 0px;
	background: rgba(9, 9, 9, 0.9);
	border: 1px solid white;
}

.vault-form .form-check input:before{
  border: 1px solid #222021;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}

.vault-form .form-check input:checked {
  opacity: 1;
  box-shadow: none;
  background-color: #FF6B00;;
  background-image: none;
  border: 1px solid white;
}

.vault-form .form-check input:checked::after {
	color: white;
	margin-top: -2px;
}

.vault-form .form-check label {
  color: white;
  font-size: 14px;
  margin-top: 2px;
}

.vault-form .form-check input[type=radio] {
	padding: 0px;
	width: 15px;
	margin: 5px;
	margin-right: 10px;
}

.vault-form .form-check input[type=checkbox] {
	padding: 0px;
	width: 20px;
	margin: 10px;
}


.vault-form-error {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.1em;
	text-transform: uppercase;

	color: #FF5454;
	text-align: center;
}
.vault-form-subtext {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: normal;
	font-size: 11px !important;
	line-height: 14px;
	text-transform: lowercase;
	text-align: center;
	color: rgba(163, 163, 163, 0.8);
}

.message-title {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 52px;
	/* identical to box height */

	text-align: center;
	text-transform: uppercase;
	color: #FF5454;

}

.message-text {
	font-family: Chakra Petch;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	text-align: center;
	color: #FFFFFF;
	margin-top: 1rem;
	margin-bottom: 3rem;
}

.vault-form-message {
	margin-top: 4rem;
	margin-bottom: 0;
}