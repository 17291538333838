.bracket {
  background-image: linear-gradient(#dad5d2, #dad5d2),
    linear-gradient(#dad5d2, #dad5d2), linear-gradient(#dad5d2, #dad5d2),
    linear-gradient(#dad5d2, #dad5d2);

  background-repeat: no-repeat;
  background-size: 8px 1px;
  background-position: top left, top right, bottom left, bottom right;

  border: solid #dad5d2;
  border-width: 0 1px;
  background: rgba(255,255,255,0.05) !important;
}

.bracket-pre, .bracket-post{
  width: 0.5em;
  border-bottom:1px solid white;
  border-top:1px solid white;
}

.bracket .content {
  padding: 0px;
}

.bracket-blue {
  background:  rgba(37, 111, 255, 0.12)  !important;
  color: rgba(37, 111, 255, 1);
  margin-right: 10px;
}

.bracket-orange {
  background: rgba(255, 168, 37, 0.12) !important;
  color:rgba(255, 107, 0, 1);
  margin-left: 10px;
}
